import { useEffect, useRef, useState } from "react";
import { api } from "../../api/api";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

import { LabTestResult } from "../../services/userService";
import LabTestTable from "../../components/LabTestTable";

import { BiSolidFilePdf } from "react-icons/bi";
import { RiPrinterFill } from "react-icons/ri";

import generatePDF, { Margin } from "react-to-pdf";
import { ReactToPrint } from "react-to-print";
import { PDFDownloadLink } from "@react-pdf/renderer";
import LabTestToPDF, { TestTable } from "../../utils/LabTestToPDF";

import TestSelectionList, {
  noTestsAvailable,
  TestSelectionListData,
} from "../../components/TestSelectionList";

const LabResults = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useAuth();
  const [token] = useState<string | undefined>(state.user?.token);
  const [tokenExpiresAt] = useState<string | undefined>(state.user?.expiration);
  const [error, setError] = useState<string | undefined>("");

  const [currentLabTestToPreview, setCurrentLabTestToPreview] =
    useState<number>(-1);
  const [testGroups, setTestGroups] = useState<Map<string, number[]>>(
    new Map()
  );
  const [filteredLabResults, setFilteredLabResults] = useState<
    LabTestResult[][]
  >([]);
  const [testSelectionListData, setTestSelectionListData] = useState<
    TestSelectionListData[]
  >([]);

  const targetRef = useRef<HTMLDivElement | null>(null);

  const downloadPDF = () => {
    try {
      const date =
        filteredLabResults[currentLabTestToPreview][0].labResult
          .requestDateTime;
      const fileName = new Date(date)
        .toLocaleString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .replace(/[/,:]/g, "-");

      generatePDF(targetRef, {
        filename: `${fileName}-lab-result-mbal-sofia.pdf`,
        method: "open",
        page: {
          margin: Margin.MEDIUM,
          format: "letter",
          orientation: "portrait",
        },
        canvas: {
          mimeType: "image/jpeg",
          qualityRatio: 1,
        },
      });
    } catch (error) {
      console.log("Error saving PDF: ", error);
    }
  };

  const getPDFSaveFileName = () => {
    try {
      const date =
        filteredLabResults[currentLabTestToPreview][0].labResult
          .requestDateTime;
      const fileName = new Date(date)
        .toLocaleString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .replace(/[/,:]/g, "-");

      return `${fileName}-lab-result-mbal-sofia.pdf`;
    } catch (error) {
      console.log("Error generating PDF name.");
    }
    return `${new Date(Date.now())
      .toLocaleString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
      .replace(/[/,:]/g, "-")}-lab-result-mbal-sofia.pdf`;
  };

  const GenerateTestSelectionListData = (
    filteredLabResults: LabTestResult[][]
  ) => {
    const listData: TestSelectionListData[] = filteredLabResults.map(
      (testGroup: LabTestResult[], index: number) => {
        return {
          id: testGroup[0].labResult.labNum || index,
          date: testGroup[0].labResult.requestDateTime || new Date(),
        };
      }
    );

    setTestSelectionListData(listData);
  };

  const groupData = (labTest: LabTestResult[]): Map<string, number[]> => {
    return labTest.reduce(
      (acc: Map<string, number[]>, test: LabTestResult, index: number) => {
        const serviceTypeName = test.labResult.serviceTypeName;

        if (!acc.has(serviceTypeName)) {
          acc.set(serviceTypeName, []);
        }

        acc.get(serviceTypeName)!.push(index);

        return acc;
      },
      new Map<string, number[]>()
    );
  };

  const handleSetCurrentLabTest = (index: number) => {
    if (
      index !== currentLabTestToPreview &&
      index < filteredLabResults.length
    ) {
      setCurrentLabTestToPreview(index);

      const groupedData = groupData(filteredLabResults[index]);
      setTestGroups(groupedData);
    }
  };

  const handleLogout = () => {
    dispatch({ type: "LOGOUT" });
  };

  const tryFetchUserTests = () => {
    fetchUserTests();
  };

  const fetchUserTests = async () => {
    try {
      const result = await api.getUserLabTests(token || "");

      // Group the data by requestID using a Map
      const groupedData = result.reduce(
        (acc: Map<number, LabTestResult[]>, test: LabTestResult) => {
          const requestID = Number(test.labResult.requestID);
          if (!acc.has(requestID)) {
            acc.set(requestID, []);
          }
          acc.get(requestID)!.push(test);
          return acc;
        },
        new Map<number, LabTestResult[]>()
      );

      const sortedKeys: number[] = Array.from(groupedData.keys())
        .map((key) => Number(key))
        .sort((a, b) => b - a);

      const groupedArray: LabTestResult[][] = sortedKeys.map(
        (key) => groupedData.get(key)!
      );

      setFilteredLabResults(groupedArray);
      GenerateTestSelectionListData(groupedArray);
    } catch (error: any) {
      if (error.response) {
        const statusCode = error.response.status;
        if (statusCode === 400 || statusCode === 401) {
          handleLogout();
        } else if (statusCode === 404) {
          setError(noTestsAvailable);
        } else {
          // Handle other HTTP errors (e.g., 403, 404, 500)
          if (error.response) {
            setError("ERROR. Please try again.");
          } else {
            setError("An unknown error occurred.");
          }
        }
      } else if (error.request) {
        // This handles network or timeout errors
        setError("Network error: Please check your internet connection.");
      } else {
        // Something happened in setting up the request that triggered an Error
        setError("Unknown error.");
      }
    }
  };

  useEffect(() => {
    const checkTokenExpiry = () => {
      if (token && tokenExpiresAt) {
        const now = new Date();

        // const expiry = new Date(tokenExpiresAt);
        // Debug token remaining time
        // const difference = expiry.getTime() - now.getTime();
        // console.log(
        //   new Intl.DateTimeFormat("en-US", {
        //     minute: "2-digit",
        //     second: "2-digit",
        //   }).format(difference)
        // );

        if (now >= new Date(tokenExpiresAt)) {
          handleLogout();
        }
      }
    };
    checkTokenExpiry();
  }, []);

  useEffect(() => {
    if (!state.user) {
      navigate("/Login");
    } else {
      fetchUserTests();
    }
  }, [state]);

  return (
    <>
      {error && error !== noTestsAvailable ? (
        <div className=" flex-1 flex flex-col text-2xl p-4 gap-10 justify-center items-center">
          <p className="bg-[var(--secondary-color)] px-6 py-4 text-white rounded-lg">
            {error}
          </p>
          <button
            onClick={() => tryFetchUserTests()}
            className="text-2xl
              px-5 py-4
              hover:bg-[var(--light-gray-color)] bg-[var(--mid-gray-color)]
              border border-opacity-20 border-black
              rounded-lg
              drop-shadow-md"
          >
            ПРЕЗАРЕДИ / RELOAD
          </button>
        </div>
      ) : (
        <>
          {/* {currentLabTestToPreview >= 0 && (
            <LabTestToPDF
              userName={state.user?.data.PersonName || ""}
              userEGN={state.user?.data.EGN || ""}
              labTest={filteredLabResults[currentLabTestToPreview]}
              testGroups={testGroups}
            />
          )} */}

          <div className="container m-auto px-2">
            <>
              {/* PAGE LABEL */}
              <div className="visible flex flex-col lg:hidden">
                {/* LAB TEST SELECTION LIST - MOBILE */}
                <TestSelectionList
                  isMobile={true}
                  list={testSelectionListData}
                  error={error || ""}
                  currentLabTestToPreview={currentLabTestToPreview}
                  handleSetCurrentLabTest={handleSetCurrentLabTest}
                />
              </div>

              {/* LAB TESTS SECTION */}
              <div className="flex mt-10 justify-start">
                {/* A4 DOCUMENT */}
                <div className="relative page text-xs">
                  {/* PRINT AND DOWNLOAD */}
                  {currentLabTestToPreview >= 0 && (
                    <div className="absolute right-[calc(30px)] pt-2 flex gap-2">
                      {/* Download PDF */}

                      <>
                        {/* REACT-TO-PDF VERSION */}
                        {/* <button onClick={() => downloadPDF()}>
                          <BiSolidFilePdf
                            size={28}
                            className="p-1 bg-black text-black rounded-sm hover:bg-[var(--secondary-color)] hover:text transition-all"
                            color="white"
                            title="Изтегли PDF / Download PDF"
                          />
                        </button> */}
                      </>
                      <>
                        {/* REACT-PDF/RENDER VERSION */}
                        <PDFDownloadLink
                          document={
                            <TestTable
                              userName={state.user?.data.PersonName || ""}
                              userEGN={state.user?.data.EGN || ""}
                              labTest={
                                filteredLabResults[currentLabTestToPreview]
                              }
                              testGroups={testGroups}
                            />
                          }
                          fileName={getPDFSaveFileName()}
                        >
                          {({ loading }) => (
                            <BiSolidFilePdf
                              size={28}
                              className="p-1 bg-black text-black rounded-sm hover:bg-[var(--secondary-color)] hover:text transition-all"
                              color="white"
                              title="Изтегли PDF / Download PDF"
                            />
                          )}
                        </PDFDownloadLink>
                      </>

                      {/* Print Document */}
                      <ReactToPrint
                        trigger={() => {
                          return (
                            <button>
                              <RiPrinterFill
                                size={28}
                                className="p-1 bg-black text-black rounded-sm hover:bg-[var(--secondary-color)] hover:text transition-all"
                                color="white"
                                title="Принтирай / Print"
                              />
                            </button>
                          );
                        }}
                        content={() => targetRef.current}
                      />
                    </div>
                  )}

                  {/* SELECTED TEST TABLE */}
                  <div ref={targetRef} className="">
                    {currentLabTestToPreview >= 0 ? (
                      <div className="flex w-full items-start">
                        <LabTestTable
                          labTestGroups={testGroups}
                          labTest={filteredLabResults[currentLabTestToPreview]}
                          user={state.user}
                        />
                      </div>
                    ) : (
                      <div className="flex w-full justify-center opacity-80 text-lg">
                        <div className="flex flex-col text-center">
                          <p>изберете изследване от таблицата за детайли...</p>
                          <p>select a test from the table for details...</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {/* LAB TEST SELECTION LIST - DESKTOP */}
                <TestSelectionList
                  isMobile={false}
                  list={testSelectionListData}
                  error={error || ""}
                  currentLabTestToPreview={currentLabTestToPreview}
                  handleSetCurrentLabTest={handleSetCurrentLabTest}
                />
              </div>
            </>
          </div>
        </>
      )}
    </>
  );
};

export default LabResults;
