export const calculateAgeFromEGN = (egn: string): number | null => {
  if (!egn) return 0;

  if (egn.length !== 10) {
    return null;
  }

  const year = parseInt(egn.substring(0, 2));
  let month = parseInt(egn.substring(2, 4));
  const day = parseInt(egn.substring(4, 6));

  let fullYear: number;

  if (month > 40) {
    fullYear = 2000 + year;
    month -= 40;
  } else if (month > 20) {
    fullYear = 1800 + year;
    month -= 20;
  } else {
    fullYear = 1900 + year;
  }

  const today = new Date();
  const birthDate = new Date(fullYear, month - 1, day);

  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();

  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  return age;
};

export const calculateAgeFromEGNOnDate = (
  egn: string,
  targetDate: Date
): number | null => {
  if (egn.length !== 10) {
    return null;
  }

  const year = parseInt(egn.substring(0, 2));
  let month = parseInt(egn.substring(2, 4));
  const day = parseInt(egn.substring(4, 6));

  let fullYear: number;

  //
  if (month > 40) {
    fullYear = 2000 + year;
    month -= 40;
  } else if (month > 20) {
    fullYear = 1800 + year;
    month -= 20;
  } else {
    fullYear = 1900 + year;
  }

  const birthDate = new Date(fullYear, month - 1, day);

  let age = targetDate.getFullYear() - birthDate.getFullYear();
  const monthDiff = targetDate.getMonth() - birthDate.getMonth();

  if (
    monthDiff < 0 ||
    (monthDiff === 0 && targetDate.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  return age;
};
