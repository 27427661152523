import { useAuth } from "../contexts/AuthContext";
import { Outlet, Navigate } from "react-router-dom";

const ProtectedRoutes = () => {
  const { state } = useAuth();

  return state.user ? <Outlet /> : <Navigate to={"/Login"} />;
};

export default ProtectedRoutes;
