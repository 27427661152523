import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div className="flex flex-col m-auto gap-20">
      <div className="flex flex-col text-center text-lg font-bold text-black bg-[var(--mid-gray-color)] p-8 rounded-md">
        <h1>ДКЦ "СВЕТА СОФИЯ" ЕООД</h1>
        <h2>Гр. София, бул. "България" 104</h2>
        <h2>тел. 02/81 84 600, факс 02/81 84 666</h2>
      </div>
      <div className="container flex w-fit p-10 flex-col items-center justify-center m-auto gap-5 text-white bg-[var(--mid-gray-color)] rounded-md">
        <Link to={"/LabResults"}>
          <button className="flex text-xl font-medium min-w-96 h-36 bg-[var(--primary-color)] justify-center items-center rounded-md">
            ЛАБОРАТОРНИ / LAB.
          </button>
        </Link>
        <Link to={"/ImagingResults"}>
          <button className="flex text-xl font-medium min-w-96 h-36 bg-[var(--secondary-color)] justify-center items-center rounded-md">
            ОБРАЗНИ / IMAGING
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Home;
