import { LabTestResult } from "../services/userService";
import TestSelectionListItem from "./TestSelectionListItem";

const desktopTestEntrisStyle = `
hidden lg:sticky lg:flex flex-col flex-shrink flex-grow
mx-2 p-2
bg-[var(--mid-gray-color)]
top-0 right-0
max-w-sm h-fit max-h-[600px]
text-xs font-light
overflow-y-auto custom-scrollbar`;

const mobileTestEntrisStyle = `
bg-[var(--mid-gray-color)]
p-5 mt-4
max-h-[400px]
text-sm font-light
shadow-lg
overflow-y-auto custom-scrollbar`;

export const noTestsAvailable = "NoTestsAvailable";

export interface TestSelectionListData {
  id: number;
  date: Date;
}

const TestListSkeleton = () => {
  return (
    <>
      <div className="flex flex-col w-full h-full gap-1">
        {Array.from({ length: 10 }).map((_, index) => (
          <div
            key={`skel-item-${index}`}
            className="
                flex w-full h-full m-auto
                bg-gray-600 text-white
                justify-center
                rounded-md
                animate-pulse
                text-3xl
              "
          >
            .....
          </div>
        ))}
      </div>
    </>
  );
};

const TestSelectionList = ({
  isMobile,
  list,
  error,
  currentLabTestToPreview,
  handleSetCurrentLabTest,
}: {
  isMobile: boolean;
  list: TestSelectionListData[];
  error: string;
  currentLabTestToPreview: number;
  handleSetCurrentLabTest: (index: number) => void;
}) => {
  return (
    <>
      <div
        className={isMobile ? mobileTestEntrisStyle : desktopTestEntrisStyle}
      >
        <div
          className="
              flex flex-col w-full h-full
              pr-1 gap-1
              rounded-md
            "
        >
          <label
            className="
                top-0 px-4 py-3 mb-4 text-white
                bg-[var(--primary-color)]
                rounded-sm border border-gray-400 shadow-md
              "
          >
            Лаб. изследвания / Lab tests
          </label>
          {list?.length > 0 || (error && error === noTestsAvailable) ? (
            list?.length > 0 ? (
              <>
                {list.map((item: TestSelectionListData, index: number) => (
                  <TestSelectionListItem
                    key={`test-${index}`}
                    testId={item?.id}
                    testDate={item.date}
                    isActive={currentLabTestToPreview === index}
                    onClick={() => {
                      handleSetCurrentLabTest(index);
                    }}
                  />
                ))}
              </>
            ) : (
              <>
                <div className="p-2 bg-orange-500 text-white">
                  Няма налични тестове / No tests available...
                </div>
              </>
            )
          ) : (
            <TestListSkeleton />
          )}
        </div>
      </div>
    </>
  );
};

export default TestSelectionList;
