import React, { useState, useRef, useEffect } from "react";
import { api } from "../../api/api";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

// Keep regex just in case
const MAIL_REGEX = /^[\w-.]+@([\w-]+\.)+[A-Za-z]{2,}$/;
const PASS_REGEX =
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&.#])[A-Za-z\d@$!%*?&.#]{6,}$/;

const TEMP_USER: string = "";
const TEMP_PASS: string = "";

const Login = () => {
  const [loginName, setLoginName] = useState(TEMP_USER);
  const [loginPass, setLoginPass] = useState(TEMP_PASS);
  const [isLoading, setIsLoading] = useState(false);
  const [incorrectLogin, setIncorrectLogin] = useState(false);

  const nameRef = useRef<HTMLInputElement>(null);
  const passRef = useRef<HTMLInputElement>(null);
  const submitBtnRef = useRef<HTMLButtonElement>(null);

  const { state, dispatch } = useAuth();
  const navigate = useNavigate();

  const handleInputUserName = (value: string) => {
    const name = value;
    setLoginName(name);
  };

  const handleInputUserPass = (value: string) => {
    const pass = value;
    setLoginPass(pass);
  };

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const name = loginName || "";
      const pass = loginPass || "";

      const validCredentials = true;

      if (validCredentials) {
        const currenUser = await api.loginUser({
          UserName: name,
          Password: pass,
        });

        if (currenUser != null) {
          dispatch({ type: "LOGIN", payload: currenUser });
        } else {
          setIncorrectLogin(true);
        }
      }
    } catch (error) {
      console.error("Login error. Please try again.");
      setIncorrectLogin(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (state.user) {
      navigate("/");
    }
  });

  const Spinner = () => <div className="spinner"></div>;

  return (
    <div className="container flex flex-col m-auto justify-center items-center">
      <form
        key={"login-form"}
        onSubmit={handleLogin}
        className="flex flex-col bg-white min-w-[360px] w-full max-w-md p-8
             text-black gap-4 text-lg border border-gray-200 rounded-lg shadow-md"
      >
        {/* <!-- Form Title --> */}
        <div className="text-center text-2xl text-[var(--primary-color)] mb-2">
          Потребителски вход / Login
        </div>

        {/* <!-- Email Label & Input --> */}
        <label className="leading-3 mt-4 text-gray-700" htmlFor="loginName">
          ИД / ID
        </label>
        <input
          required
          id={"loginName"}
          ref={nameRef}
          type="text"
          value={loginName}
          disabled={isLoading}
          placeholder="Въведете ИД / Enter ID"
          autoComplete="email"
          onChange={(e) => handleInputUserName(e.target.value)}
          className="p-2 border border-gray-300 rounded-md focus:outline-none 
                  focus:ring-2 focus:ring-[var(--secondary-color)] focus:border-transparent"
        />

        {/* <!-- Password Label & Input --> */}
        <label
          className="text-lg leading-3 mt-4 text-gray-700"
          htmlFor="loginPass"
        >
          Парола / Password
        </label>
        <input
          required
          id={"loginPass"}
          ref={passRef}
          type="password"
          value={loginPass}
          disabled={isLoading}
          placeholder="Въведете Парола / Enter Password"
          autoComplete="current-password"
          onChange={(e) => handleInputUserPass(e.target.value)}
          className="p-2 border border-gray-300 rounded-md focus:outline-none 
                  focus:ring-2 focus:ring-[var(--secondary-color)] focus:border-transparent"
        />

        {/* <!-- Submit Button --> */}
        <div className="flex w-full mt-5">
          <button
            type="submit"
            ref={submitBtnRef}
            disabled={isLoading}
            className={`relative flex w-full justify-center py-2 px-4 rounded-lg text-white transition-colors
                ${
                  isLoading
                    ? "bg-slate-700 cursor-not-allowed"
                    : "bg-[var(--primary-color)] hover:bg-[var(--secondary-color)]"
                }`}
          >
            {isLoading ? (
              <div className="flex items-center">
                <div className="absolute left-5">
                  <Spinner />
                </div>
                <div>Зареждане / Loading</div>
              </div>
            ) : (
              "Вход / Login"
            )}
          </button>
        </div>

        {/* <!-- Error Message --> */}
        {incorrectLogin && (
          <span className="text-red-600 text-sm">
            Грешни имейл или парола / Wrong credentials
          </span>
        )}
      </form>
      <>
        <div
          className="min-w-[360px] max-w-md p-4 mt-20 sm:mt-10 text-xl sm:text-sm leading-tight
          bg-gray-100
          outline outline-[var(--secondary-color)] outline-1
          
          "
        >
          Потребителско име и парола не могат да бъдат предоставяни по телефона,
          на e-mail или друг комуникационен канал в мрежата. С оглед защитата на
          Вашите лични данни, те се{" "}
          <span className="font-medium bg-[var(--secondary-color)] text-white rounded-sm px-1">
            получават лично.
          </span>
        </div>
      </>
    </div>
  );
};

export default Login;
