import { useEffect, useRef, useState } from "react";
import { api } from "../../api/api";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

import { ImagingTest } from "../../services/userService";

import { BiSolidFilePdf } from "react-icons/bi";
import { RiPrinterFill } from "react-icons/ri";

import ReactToPrint from "react-to-print";
import generatePDF, { Margin } from "react-to-pdf";

import { calculateAgeFromEGNOnDate } from "../../utils/Misc";
import TestSelectionList, {
  noTestsAvailable,
  TestSelectionListData,
} from "../../components/TestSelectionList";

const mbalLogo = "/logo-mbal-2.jpg";
const iconImagingFooter = "/icon-imaging-footer.jpg";

const ImagingResults = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useAuth();
  const [token] = useState<string | undefined>(state.user?.token);
  const [tokenExpiresAt] = useState<string | undefined>(state.user?.expiration);
  const [error, setError] = useState<string | undefined>("");
  const [currentLabTestToPreview, setCurrentLabTestToPreview] =
    useState<number>(-1);
  const [tests, setTests] = useState<ImagingTest[]>([]);
  const [testSelectionListData, setTestSelectionListData] = useState<
    TestSelectionListData[]
  >([]);

  const targetRef = useRef<HTMLDivElement>(null);

  const handleLogout = () => {
    dispatch({ type: "LOGOUT" });
  };

  const GenerateTestSelectionListData = (filteredLabResults: ImagingTest[]) => {
    const listData: TestSelectionListData[] = filteredLabResults.map(
      (item: ImagingTest, index: number) => {
        return {
          id: item.imagingResult.id || index,
          date: item.imagingResult.date || new Date(),
        };
      }
    );

    setTestSelectionListData(listData);
  };

  const tryFetchImagingResultData = () => {
    fetchImagingResultData();
  };

  const fetchImagingResultData = async () => {
    try {
      const result = await api.getUserImagingTests(token || "");
      const sortedList = result.sort(
        (a: ImagingTest, b: ImagingTest) =>
          b.imagingResult.id - a.imagingResult.id
      );

      setTests(sortedList);
      GenerateTestSelectionListData(sortedList);
    } catch (error: any) {
      if (error.response) {
        const statusCode = error.response.status;
        if (statusCode === 400 || statusCode === 401) {
          handleLogout();
        } else if (statusCode === 404) {
          setError(noTestsAvailable);
        } else {
          // Handle other HTTP errors (e.g., 403, 404, 500)
          if (error.response) {
            setError("ERROR. Please try again.");
          } else {
            setError("An unknown error occurred.");
          }
        }
      } else if (error.request) {
        // This handles network or timeout errors
        setError("Network error: Please check your internet connection.");
      } else {
        // Something happened in setting up the request that triggered an Error
        setError("Unknown error.");
      }
    }
  };

  const handleSetCurrentLabTest = (index: number) => {
    if (index !== currentLabTestToPreview) {
      setCurrentLabTestToPreview(index);
    }
  };

  useEffect(() => {
    const checkTokenExpiry = () => {
      if (token && tokenExpiresAt) {
        const now = new Date();

        // const expiry = new Date(tokenExpiresAt);
        // Debug token remaining time
        // const difference = expiry.getTime() - now.getTime();
        // console.log(
        //   new Intl.DateTimeFormat("en-US", {
        //     minute: "2-digit",
        //     second: "2-digit",
        //   }).format(difference)
        // );

        if (now >= new Date(tokenExpiresAt)) {
          handleLogout();
        }
      }
    };
    checkTokenExpiry();
  }, []);

  useEffect(() => {
    if (!state.user) {
      navigate("/Login");
    } else {
      fetchImagingResultData();
    }
  }, [state]);

  const downloadPDF = () => {
    try {
      const date = tests[currentLabTestToPreview].imagingResult.date;
      const fileName = new Date(date)
        .toLocaleString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .replace(/[/,:]/g, "-");

      generatePDF(targetRef, {
        filename: `${fileName}-imaging-result-mbal-sofia.pdf`,
        method: "save",
        page: {
          margin: Margin.MEDIUM,
          format: "letter",
          orientation: "portrait",
        },
        canvas: {
          mimeType: "image/jpeg",
          qualityRatio: 1,
        },
      });
    } catch (error) {
      console.log("Error saving PDF: ", error);
    }
  };

  const tryRemoveDateFromText = (text: string) => {
    const dateIndex = text.indexOf("Дата: ");
    const cleanedText =
      dateIndex !== -1
        ? tests[currentLabTestToPreview].imagingResult.text.slice(0, dateIndex)
        : text;
    return cleanedText;
  };

  return (
    <>
      {error && error !== noTestsAvailable ? (
        <div className=" flex-1 flex flex-col text-2xl p-4 gap-10 justify-center items-center">
          <p className="bg-[var(--secondary-color)] px-6 py-4 text-white rounded-lg">
            {error}
          </p>
          <button
            onClick={() => tryFetchImagingResultData()}
            className="text-2xl
            px-5 py-4
            hover:bg-[var(--light-gray-color)] bg-[var(--mid-gray-color)]
            border border-opacity-20 border-black
            rounded-lg
            drop-shadow-md"
          >
            ПРЕЗАРЕДИ / RELOAD
          </button>
        </div>
      ) : (
        <div className="container m-auto px-2">
          <>
            {/* IMAGING TEST SELECTION LIST - MOBILE */}
            <div className="visible flex flex-col lg:hidden">
              <TestSelectionList
                isMobile={true}
                list={testSelectionListData}
                error={error || ""}
                currentLabTestToPreview={currentLabTestToPreview}
                handleSetCurrentLabTest={handleSetCurrentLabTest}
              />
            </div>
            {/* LAB TESTS SECTION */}
            <div className="relative flex mt-10 justify-start">
              {/* A4 DOCUMENT */}
              <div className="relative page text-xs">
                {/* PRINT AND DOWNLOAD */}
                {currentLabTestToPreview >= 0 && (
                  <div className="absolute right-[calc(30px)] pt-2 flex gap-2">
                    {/* Download PDF */}
                    <button onClick={() => downloadPDF()}>
                      <BiSolidFilePdf
                        size={28}
                        className=" p-1 bg-black text-black rounded-sm hover:bg-[var(--secondary-color)] hover:text transition-all"
                        color="white"
                        title="Изтегли PDF / Download PDF"
                      />
                    </button>
                    {/* Print Document */}
                    <ReactToPrint
                      trigger={() => {
                        return (
                          <button>
                            <RiPrinterFill
                              size={28}
                              className=" p-1 bg-black text-black rounded-sm hover:bg-[var(--secondary-color)] hover:text transition-all"
                              color="white"
                              title="Принтирай / Print"
                            />
                          </button>
                        );
                      }}
                      content={() => targetRef.current}
                    />
                  </div>
                )}

                {/* SELECTED TEST TABLE */}
                <div ref={targetRef} className="p-[0mm]">
                  {currentLabTestToPreview >= 0 ? (
                    <>
                      <div className="flex flex-col w-full h-fit bg-gray-100 border border-gray-400 mb-3">
                        <div className="flex w-full p-4">
                          <img
                            src={mbalLogo}
                            alt="MBAL Logo"
                            className="flex w-32 h-32 object-contain"
                          />
                          <div className="flex flex-col w-full text-base text-center font-bold text-black px-5 rounded-md">
                            <h2 className="flex flex-col h-full justify-end">
                              <span>
                                МНОГОПРОФИЛНА БОЛНИЦА ЗА АКТИВНО ЛЕЧЕНИЕ{" "}
                                <span className="whitespace-nowrap">
                                  “СВЕТА СОФИЯ”
                                </span>
                              </span>

                              <span>
                                гр. София 1618, бул. „България” № 104,{" "}
                                <span className="whitespace-nowrap">
                                  тел. 02/81 84 630
                                </span>
                              </span>
                            </h2>
                          </div>
                        </div>
                      </div>
                      <div className="flex h-fit justify-center text-base text-center font-bold py-10">
                        РЕЗУЛТАТ ОТ ОБРАЗНО – ДИАГНОСТИЧНО ИЗСЛЕДВАНЕ ОТ
                        ОТДЕЛЕНИЕ ПО ОБРАЗНА ДИАГНОСТИКА
                      </div>
                      <div className="flex h-fit py-1 font-bold">
                        Пациент:{" "}
                        <div className="pl-1">
                          {state.user?.data.PersonName}
                        </div>
                        ,
                        <div className="pl-1">
                          {state.user?.data.EGN
                            ? calculateAgeFromEGNOnDate(
                                state.user?.data.EGN,
                                new Date(
                                  tests[
                                    currentLabTestToPreview
                                  ].imagingResult.date
                                )
                              ) + "г."
                            : ""}
                          {", "}
                          Рег. номер - {state.user?.data.PersonID}
                        </div>
                      </div>
                      <div className="flex h-fit py-1 font-bold mt-5">
                        {/* Koмпютъртомографско изследване */}
                        {tests[currentLabTestToPreview].imagingResult.service}
                      </div>
                      <div className="flex flex-col h-fit py-5 gap-2 leading-4">
                        {tryRemoveDateFromText(
                          tests[currentLabTestToPreview].imagingResult.text
                        )}

                        {/* SPLIT TEXT */}
                        {/* NOTE : attempt to split imagingResult.text */}
                        {/* {tests[currentLabTestToPreview].text
                      // .split(/(?<!\b[А-Я]{,})\s+(?=[А-Я])/g)
                      .split("  ")
                      .map((item: string, id) => (
                        <div
                          key={id}
                          className={`
                              ${
                                item.includes(
                                  "Koмпютъртомографско изследване"
                                ) ||
                                item.includes("КТ находка:") ||
                                item.includes("Торакс:") ||
                                item.includes("Торакс:") ||
                                item.toUpperCase().includes("ЗАКЛЮЧЕНИЕ:")
                                  ? "text-base font-extrabold pt-4"
                                  : ""
                              }`}
                        >
                          {item || <br />}
                        </div>
                      ))} */}
                      </div>
                      <div className="flex h-fit py-1 font-bold">
                        {
                          tests[currentLabTestToPreview].physistDetails
                            .PhysicianFullName
                        }
                      </div>
                      <div className="flex h-fit py-1 font-bold">
                        {new Date(
                          tests[currentLabTestToPreview].imagingResult.date
                        ).toLocaleDateString("BG")}
                      </div>

                      {/* INFO GROUP */}
                      <div className="flex mt-10">
                        {/* <FooterInfoGroup /> */}
                        <img src={iconImagingFooter} />
                      </div>
                    </>
                  ) : (
                    <div className="flex w-full justify-center opacity-80 text-lg">
                      <div className="flex flex-col text-center">
                        <p>изберете изследване от таблицата за детайли...</p>
                        <p>select a test from the table for details...</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* IMAGING TEST SELECTION LIST - DESKTOP */}
              <TestSelectionList
                isMobile={false}
                list={testSelectionListData}
                error={error || ""}
                currentLabTestToPreview={currentLabTestToPreview}
                handleSetCurrentLabTest={handleSetCurrentLabTest}
              />
            </div>
          </>
        </div>
      )}
    </>
  );
};

export default ImagingResults;
