import axios from "axios";

const API_URL_LOGIN =
  "https://revolutionlab-001-site8.dtempurl.com/Authenticate/login";

interface LoginUser {
  UserName: string;
  Password: string;
}

export const loginUser = async (user: LoginUser) => {
  try {
    const response = await axios.post(API_URL_LOGIN, {
      UserName: user.UserName,
      Password: user.Password,
    });

    return response.data;
  } catch (error) {
    console.error("Error logging user.");
    throw error;
  }
};
