import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  PDFDownloadLink,
  Font,
  PDFViewer,
} from "@react-pdf/renderer";
const mbalLogo = "/logo-mbal-2.jpg";
const imgDrSign = "/sign-dr.jpg";

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: "/fonts/roboto/Roboto-Regular.ttf",
      fontWeight: 400,
    },
    {
      src: "/fonts/roboto/Roboto-Medium.ttf",
      fontWeight: 500,
    },
  ],
});

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    width: "100%",
    fontSize: 8,
    fontFamily: "Roboto",
    border: "1px solid #cbd5e0",
  },
  section: {
    flexDirection: "column",
    width: "100%",
    fontSize: 8,
    fontFamily: "Roboto",
    // border: "1px solid #cbd5e0",
  },
  headerLabelBold: {},
  logo: {
    width: 64,
    height: 64,
  },
  headerTextContainer: {
    flex: 1,
    fontSize: 14,
    flexDirection: "column",
    textAlign: "center",
    justifyContent: "center",
    color: "black",
    fontWeight: "medium",
  },
  title: {
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    paddingVertical: 8,
    fontSize: 14,
    borderBottom: "1px solid #cbd5e0",
    fontWeight: "medium",
  },
  tableRowGroup: {
    display: "flex",
    width: "50%",
    fontSize: 10,
    paddingLeft: 4,
    paddingVertical: 6,
    height: "100%",
    borderRight: "1px solid #cbd5e0",
    backgroundColor: "#bbb",
  },
  tableRowLabel: {
    display: "flex",
    width: "50%",
    height: "100%",
    paddingLeft: 8,
    justifyContent: "center",
    backgroundColor: "#ddd",
    borderRight: "1px solid #cbd5e0",
  },
  tableRowName: {
    display: "flex",
    width: "50%",
    height: "100%",
    paddingLeft: 10,
    justifyContent: "center",
    borderRight: "1px solid #cbd5e0",
  },
});

const getPatoState = (hasResult: boolean, patoStateID: number) => {
  switch (patoStateID) {
    case 0:
      return hasResult ? "-" : "";
    case 1:
      return "L";
    case 2:
      return "H";
    default:
      return "";
  }
};

export const TestTable = ({
  userName,
  userEGN,
  testGroups,
  labTest,
}: {
  userName: string;
  userEGN: string;
  testGroups: Map<string, number[]>;
  labTest: any;
}) => {
  return (
    <Document>
      <Page size="A4" style={{ padding: "10mm" }}>
        <View style={styles.container}>
          {/* MBAL INFO HEADER TABLE */}
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              borderBottom: "1px solid #cbd5e0",
              padding: "10px",
            }}
          >
            <Image src={mbalLogo} style={styles.logo} />
            <View style={styles.headerTextContainer}>
              <Text>ДКЦ "СВЕТА СОФИЯ" ЕООД</Text>
              <Text>Гр. София, бул. "България" 104</Text>
              <Text>тел. 02/81 84 600, факс 02/81 84 666</Text>
            </View>
          </View>
          <Text style={styles.title}>РЕЗУЛТАТИ ОТ ЛАБОРАТОРНИ ИЗСЛЕДВАНИЯ</Text>

          {/* USER INFO HEADER TABLE */}
          <View
            style={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row",
              width: "100%",
              height: "64px",
            }}
          >
            {/* PATIENT INFO */}
            <View
              style={{
                display: "flex",
                width: "50%",
                height: "50%",
                borderBottom: "1px solid #cbd5e0",
                borderRight: "1px solid #cbd5e0",
              }}
            >
              <View
                style={{
                  display: "flex",
                  height: "100%",
                  gap: "4px",
                  padding: "4px",
                  //   backgroundColor: "cyan",
                }}
              >
                <Text style={{ fontWeight: "medium" }}>Пациент</Text>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <View style={{ display: "flex", flexDirection: "row" }}>
                    <Text style={{ fontWeight: "medium" }}>Име: </Text>
                    <Text style={{ fontWeight: "normal" }}>{userName}</Text>
                  </View>
                  <View style={{ display: "flex", flexDirection: "row" }}>
                    <Text style={{ fontWeight: "medium" }}>ЕНГ: </Text>
                    <Text style={{ fontWeight: "normal" }}>{userEGN}</Text>
                  </View>
                </View>
              </View>
            </View>

            {/* DEPARTMENT INFO */}
            <View
              style={{
                display: "flex",
                width: "50%",
                height: "50%",
                padding: "4px",
                borderBottom: "1px solid #cbd5e0",
              }}
            >
              <View
                style={{
                  display: "flex",
                  height: "100%",
                  gap: "4px",
                }}
              >
                <Text style={{ fontWeight: "medium" }}>Отделение</Text>
                <Text>КЛИНИЧНА ЛАБОРАТОРИЯ</Text>
              </View>
            </View>

            {/* TEST INFO */}
            <View
              style={{
                display: "flex",
                width: "50%",
                height: "50%",
                padding: "4px",
                borderRight: "1px solid #cbd5e0",
              }}
            >
              <View
                style={{
                  display: "flex",
                  height: "100%",
                  gap: "4px",
                }}
              >
                <Text style={{ fontWeight: "medium" }}>Изследване</Text>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <View style={{ display: "flex", flexDirection: "row" }}>
                    <Text style={{ fontWeight: "medium" }}>N: </Text>
                    <Text style={{ fontWeight: "normal" }}>
                      {labTest[0].labResult.labNum}
                    </Text>
                  </View>
                  <View style={{ display: "flex", flexDirection: "row" }}>
                    <Text style={{ fontWeight: "medium" }}>Дата: </Text>
                    <Text style={{ fontWeight: "normal" }}>
                      {convertToDate(
                        labTest[0]?.physistDetails.RequestDateTime || "-----"
                      )}
                    </Text>
                  </View>
                </View>
              </View>
            </View>

            {/* SENDING DOCTOR  */}
            <View
              style={{
                display: "flex",
                width: "50%",
                height: "50%",
                padding: "4px",
              }}
            >
              <View
                style={{
                  display: "flex",
                  height: "100%",
                  gap: "4px",
                }}
              >
                <Text style={{ fontWeight: "medium" }}>Изпращащ лекар</Text>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <View style={{ display: "flex", flexDirection: "row" }}>
                    <Text style={{ fontWeight: "medium" }}>Име: </Text>
                    <Text style={{ fontWeight: "normal" }}>
                      {labTest[0]?.physistDetails.ReqPhysistNAme || "Д-р -----"}
                    </Text>
                  </View>
                  <View style={{ display: "flex", flexDirection: "row" }}>
                    <Text style={{ fontWeight: "medium" }}>Дата: </Text>
                    <Text style={{ fontWeight: "normal" }}>
                      {convertToDate(
                        labTest[0]?.labResult.requestDateTime || "-----"
                      )}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>

          {/* RESULTS TABLE HEADER */}
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              height: "28px",
              textAlign: "left",
              alignItems: "center",
              borderTop: "1px solid #cbd5e0",
            }}
          >
            <View
              style={{
                display: "flex",
                width: "50%",
                height: "100%",
                borderRight: "1px solid #cbd5e0",
                justifyContent: "center",
                padding: "4px",
              }}
            >
              <Text>Група/Показател</Text>
            </View>
            <View
              style={{
                display: "flex",
                width: "10%",
                height: "100%",
                padding: "4px",
                alignItems: "center",
                justifyContent: "center",
                borderRight: "1px solid #cbd5e0",
              }}
            >
              <Text>Резултат</Text>
            </View>
            <View
              style={{
                display: "flex",
                width: "10%",
                height: "100%",
                padding: "4px",
                alignItems: "center",
                justifyContent: "center",
                borderRight: "1px solid #cbd5e0",
              }}
            >
              <Text>Единици</Text>
            </View>
            <View
              style={{
                display: "flex",
                width: "10%",
                height: "100%",
                padding: "4px",
                alignItems: "center",
                justifyContent: "center",
                borderRight: "1px solid #cbd5e0",
              }}
            >
              <Text>Долна гр.</Text>
            </View>
            <View
              style={{
                display: "flex",
                width: "10%",
                height: "100%",
                padding: "4px",
                alignItems: "center",
                justifyContent: "center",
                borderRight: "1px solid #cbd5e0",
              }}
            >
              <Text>Горна гр.</Text>
            </View>
            <View
              style={{
                display: "flex",
                width: "10%",
                height: "100%",
                padding: "4px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text>Патолог.</Text>
            </View>
          </View>

          {/* RESULTS TABLE */}
          <View style={{ display: "flex", width: "100%" }}>
            <>
              {Array.from(testGroups.entries()).map(
                ([serviceTypeName, ids], testGroupIndfex) => (
                  <React.Fragment key={`group-fragment-${testGroupIndfex}`}>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        borderTop: "1px solid #cbd5e0",
                      }}
                    >
                      <View style={styles.tableRowGroup}>
                        <Text>{serviceTypeName}</Text>
                      </View>
                      <View
                        style={{
                          display: "flex",
                          width: "10%",
                          height: "100%",
                          borderRight: "1px solid #cbd5e0",
                        }}
                      ></View>
                      <View
                        style={{
                          display: "flex",
                          width: "10%",
                          height: "100%",
                          borderRight: "1px solid #cbd5e0",
                        }}
                      ></View>
                      <View
                        style={{
                          display: "flex",
                          width: "10%",
                          height: "100%",
                          borderRight: "1px solid #cbd5e0",
                        }}
                      ></View>
                      <View
                        style={{
                          display: "flex",
                          width: "10%",
                          height: "100%",
                          borderRight: "1px solid #cbd5e0",
                        }}
                      ></View>
                      <View
                        style={{
                          display: "flex",
                          width: "10%",
                          height: "100%",
                          // borderRight: "1px solid #cbd5e0",
                        }}
                      ></View>
                    </View>

                    {ids.map((id, idsIndex) => (
                      <React.Fragment key={`test-entry-${idsIndex}`}>
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            textAlign: "left",
                            alignItems: "center",
                            width: "100%",
                            height: "24px",
                            borderTop: "1px solid #cbd5e0",
                          }}
                        >
                          <View
                            style={
                              labTest[id].labResult.result
                                ? styles.tableRowName
                                : styles.tableRowLabel
                            }
                          >
                            <Text>
                              {labTest[id]?.labResult?.name.length > 0 &&
                              labTest[id]?.labResult?.name?.[0] === " "
                                ? "\t" + labTest[id].labResult.name.slice(1)
                                : labTest[id].labResult.name}
                              {/* {labTest[id].labResult.name} */}
                            </Text>
                          </View>
                          <View
                            style={{
                              display: "flex",
                              width: "10%",
                              height: "100%",
                              padding: "4px",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRight: "1px solid #cbd5e0",
                            }}
                          >
                            <Text>{labTest[id].labResult.result}</Text>
                          </View>
                          <View
                            style={{
                              display: "flex",
                              width: "10%",
                              height: "100%",
                              padding: "4px",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRight: "1px solid #cbd5e0",
                            }}
                          >
                            <Text>{labTest[id].labResult.mUnit}</Text>
                          </View>
                          <View
                            style={{
                              display: "flex",
                              width: "10%",
                              height: "100%",
                              padding: "4px",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRight: "1px solid #cbd5e0",
                            }}
                          >
                            <Text>{labTest[id].labResult.highLimit}</Text>
                          </View>
                          <View
                            style={{
                              display: "flex",
                              width: "10%",
                              height: "100%",
                              padding: "4px",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRight: "1px solid #cbd5e0",
                            }}
                          >
                            <Text>{labTest[id].labResult.lowLimit}</Text>
                          </View>
                          <View
                            style={{
                              display: "flex",
                              width: "10%",
                              height: "100%",
                              padding: "4px",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Text>
                              {getPatoState(
                                labTest[id].labResult.result,
                                labTest[id].labResult.patoStateID
                              )}
                            </Text>
                          </View>
                        </View>
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                )
              )}
            </>
          </View>
        </View>

        <View style={styles.section}>
          {/* COMMENT */}
          <View
            style={{
              padding: 4,
            }}
          >
            <View
              style={{ display: "flex", flexDirection: "row", marginTop: 10 }}
            >
              <Text>Коментар: </Text>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Text style={{ fontWeight: "medium" }}>L </Text>
                <Text>- Резултатът е под долна граница</Text>
              </View>
            </View>
            <View style={{ display: "flex", flexDirection: "row" }}>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: 41,
                }}
              >
                <Text style={{ fontWeight: "medium" }}>R </Text>
                <Text> - Резултатът е над горна граница</Text>
              </View>
            </View>
          </View>

          {/* NOTE */}
          <View
            style={{
              display: "flex",
              maxWidth: 400,
              padding: 10,
              textAlign: "center",
              alignSelf: "center",
            }}
          >
            <Text>
              Резултатите от изследванията не са диагноза, те трябва да бъдат
              тълкувани от Лекуващия лекар в контекста на общото състояние на
              болния
            </Text>
          </View>

          {/* DOCTOR */}
          <View
            style={{
              display: "flex",
              width: "100%",
              maxHeight: 500,
              padding: 60,
              gap: 4,
              alignItems: "flex-end",
            }}
          >
            <Text>
              /{labTest[0]?.physistDetails.ExecPhysName || "Др. -----"}/
            </Text>
            <View
              style={{
                width: "50%",
                border: "1px solid #cbd5e0",
              }}
            >
              <Image src={imgDrSign} />
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const convertToDate = (dateTime: string) => {
  return (
    new Date(dateTime)
      .toLocaleString("bg-BG", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: undefined,
        hour12: false,
      })
      .replace(/\sг\./, "") || "-----"
  );
};

const LabTestToPDF = ({
  userName,
  userEGN,
  testGroups,
  labTest,
}: {
  userName: string;
  userEGN: string;
  testGroups: Map<string, number[]>;
  labTest: any;
}) => {
  console.log("testGroups");
  console.log(testGroups);
  // console.log(labTest);

  return (
    <>
      {/* PREVIEW PDF IN BROWSER */}
      {/* <PDFViewer style={{ width: "100%", height: "100vh" }}>
        <TestTable
          userName={userName}
          userEGN={userEGN}
          testGroups={testGroups}
          labTest={labTest}
        />
      </PDFViewer> */}

      {/* DOWNLOAD PDF */}
      <PDFDownloadLink
        document={
          <TestTable
            userName={userName}
            userEGN={userEGN}
            testGroups={testGroups}
            labTest={labTest}
          />
        }
        fileName="lab_test_results.pdf"
      >
        {({ loading }) => (loading ? "Loading document..." : "Download PDF")}
      </PDFDownloadLink>
    </>
  );
};

export default LabTestToPDF;
