import React from "react";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  const navigate = useNavigate();

  const navigateToHomepage = () => {
    navigate("/"); // Navigate to home page
  };

  return (
    <div className="flex flex-col items-center justify-center w-full h-full m-auto">
      <h1 className="text-9xl font-bold text-[var(--secondary-color)] mb-4">
        404
      </h1>
      <p className="text-2xl text-gray-700 mb-8">
        Oops! The page doesn't exist.
      </p>
      <button
        onClick={navigateToHomepage}
        className="px-6 py-3 text-lg text-white bg-[var(--secondary-color)] rounded hover:bg-red-600 transition-colors duration-300"
      >
        Назад към меню / Load Homepage
      </button>
    </div>
  );
};

export default PageNotFound;
