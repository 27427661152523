import { BrowserRouter, Routes, Route } from "react-router-dom";

import Headerbar from "./components/Headerbar";
import Footer from "./components/Footer";
import Home from "./pages";
import Login from "./pages/Login";
import LabResults from "./pages/LabResults";
import ImagingResults from "./pages/ImagingResults";
import ProtectedRoutes from "./utils/ProtectedRoutes";
import NotFound from "./pages/NotFound";

import "./App.css";

function App() {
  return (
    <main className="App">
      <div className="min-h-screen flex flex-col">
        <BrowserRouter>
          <header className="App-header">
            <Headerbar />
          </header>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route element={<ProtectedRoutes />}>
              <Route path="/" element={<Home />} />
              <Route path="/LabResults" element={<LabResults />} />
              <Route path="/ImagingResults" element={<ImagingResults />} />
              <Route path="/*" element={<NotFound />} />
            </Route>
          </Routes>
        </BrowserRouter>
        <Footer />
      </div>
    </main>
  );
}

export default App;
